import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";

import keval from "../../assets/parents/keval.png";
import navya from "../../assets/parents/Navya.png";
import shiv from "../../assets/parents/shiv.png";
import jograj from "../../assets/parents/jograj.png";
import bhavya from "../../assets/parents/bhavya.png";
import jinal from "../../assets/parents/jinal.png";
import priti from "../../assets/parents/priti.png";
import suraj from "../../assets/parents/Suraj.png";
import yash from "../../assets/parents/yash.png";

import b from "../../assets/parents/b.png";
import c from "../../assets/parents/c.jpg";
import d from "../../assets/parents/d.png";
import e from "../../assets/parents/e.png";
import f from "../../assets/parents/f.jpg";
import s1 from "../../assets/students/s1.jpg";
import s2 from "../../assets/students/s2.jpg";
import s3 from "../../assets/students/s3.jpg";
import paTopIcon from "../../assets/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={keval}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Keval Gadhvi (Class VII)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are associated with Hermann Gmeiner School, Gada since 2011.
                My elder son – Kartavya had also studied in this school. We are
                satisfied with school and teachers. School is doing noble work
                of providing quality education in rural area. Many students from
                our village – Lakhond have been coming to school. We are very
                much thankful towards school for humane approach towards parents
                & students during COVID-19.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Navya Makwana (Class III)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My daughter has been studying in the school since Class I. we
                are very satisfied with teaching of the school. Navya enjoys
                coming to school every day . School gives regular updates in
                school group so we keep aware about various activities happen at
                school.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={navya}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={shiv}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Shiv Kothivar (KG)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are happy with progress of our child. Shiv likes various
                activities happened in his class and he participate in it with
                lots of enthusiasm. School regularly sends update about
                activities, homework regularly in group. We are satisfied with
                education and approach by staff of school.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Jograjsinh Sodha (Class V))</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Jograj is studying with Hermann Geminer School since Class I.
                School is really doing good work by giving quality education to
                rural student at very affordable fees. Approach of teachers is
                very cooperative. They motivate student to participate in class
                actively. We are satisfied with performance of our child so far.
              </p>
            </div>
            <div className="parenttimage">
              <img
                src={jograj}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          {/*  */}

          {/* students' voice */}
          <div
            className={`f-2 f-500 mt-5  gap-4`}
            style={{ color: "#1FDC00ED" }}
          >
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
              }}
            />
            Students' Voice
          </div>
          <div className="" style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={suraj}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Suraj Garva Class IX</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I have been studying in this school since class I (2014) .
                Atmosphere of school campus is very peaceful and full of nature
                with lots of trees and flowers. Many Co- curricular activities
                happen in school every month. I feel like home at my school due
                to caring teachers .
              </p>
            </div>
          </div>
          <div className="parents_voicee ">
            <div className="voicetext">
              <p style={{ marginTop: "3rem" }}>
                <b>Jinal Rabari Class III </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am studying in the school since KG. My teachers are very good.
                They teach through various activities and it is really makes our
                learning with fun. I love my school very much.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={jinal}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div className="parents__voice gap-4">
            <div className="parentimage">
              <img
                src={priti}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Priti Vankar Class V</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am studying in class V. My School celebrates each festival
                with lots of activities; I really like this about school. I
                learn new things every day from my teachers. I am studying in
                the school class I.
              </p>
            </div>
          </div>
          <div className="parents__voice ">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Bhavya Mnageria Class VII</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am studying in the school since 2015. My school has big
                playground. We play lots of athletics games and various indoor
                games during Sports period. School encourages student very well
                and also make sure that each student participate in school
                activities. Teachers are very cordial and supportive.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={bhavya}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>
          </div>
          <div className="parents__voice gap-4 ">
            <div className="parentimage">
              <img
                src={yash}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
              />
            </div>

            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Yash Goyal Class VI</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Teachers teach every topic in detail and with lots of
                activities. School has all basic amenities and maintained very
                well. We celebrate each festival in school with lots of zeal and
                enthusiasm.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
