import React, { useState } from "react";
import ReactPlayer from "react-player";

import campus from "../../video/Virtual Tour.mp4";

import "./Landing.css";
import slider1 from "../../assets/slider/C1.JPG";
import slider2 from "../../assets/slider/ClubsCover.JPG";
import slider3 from "../../assets/final/SocialImpact.jpg";
import slider4 from "../../assets/slider/playground_1.jpg";
import slider5 from "../../assets/slider/sportscover.JPG";
import slider6 from "../../assets/slider/trip2.JPG";
import slider7 from "../../assets/slider/OtherImportantEvents.png";
import slider8 from "../../assets/slider/life_skill_cover.jpg";
import slider9 from "../../assets/final/LifeSkill.jpg";
import slider10 from "../../assets/final/PedagogyCover.JPG";

import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

function Landing({ video, cover }) {
  const [isMuted, setIsMuted] = useState(true);

  return video ? (
    <div className="position-relative">
      {/* <ReactPlayer
        url={campus}
        loop={true}
        muted={isMuted}
        playing={true}
        controls={false}
        width="100%"
        className="landing_video"
      />
      <GiSpeaker
        size={50}
        className="sound_icon  cursor"
        style={{ display: !isMuted ? null : "none" }}
        onClick={() => setIsMuted(true)}
      />
      <GiSpeakerOff
        size={50}
        className="sound_icon cursor"
        style={{ display: isMuted ? null : "none" }}
        onClick={() => setIsMuted(false)}
      /> */}
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="sliderAlign" src={slider1} alt="hi" />
          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Nizamuddin
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider2} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Raipur
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider3} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Bhakatpara
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider4} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider5} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Nizamuddin
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider6} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Raipur
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img className="sliderAlign" src={slider7} alt="" />
          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner Vocational Training Institute, Bhakatpara
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider8} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider9} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="sliderAlign" src={slider10} alt="" />

          <center>
            {/* <div className="bottomleft box">
              <p className="h">
                Hermann Gmeiner After School Vocational Training Institute{" "}
              </p>
            </div> */}
          </center>
        </SwiperSlide>
      </Swiper>
    </div>
  ) : (
    <div className="cover_img_cont">
      <img src={cover} alt="images" className="cover_img" />
    </div>
  );
}

export default Landing;
